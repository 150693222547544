import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteService, getServicesList, postService, putService } from "../../api/services";
import { servicesSliceName } from "../reducers/servicesSlice";

export const getServices = createAsyncThunk(
  `${servicesSliceName}/list`,
  async (queryObj, thunkAPI) => {
    try {
      const response = await getServicesList(queryObj);

      const sortedServices = [...response.data].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;

        if (nameA > nameB) return 1;

        return 0;
      });

      return {
        data: sortedServices,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addService = createAsyncThunk(
  `${servicesSliceName}/add`,
  async ({ name, description, price, duration_minutes, is_active }, thunkAPI) => {
    try {
      const response = await postService({
        serviceData: { name, description, price, duration_minutes, is_active },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateService = createAsyncThunk(
  `${servicesSliceName}/update`,
  async ({ name, description, price, duration_minutes, is_active, id }, thunkAPI) => {
    try {
      const response = await putService({
        serviceData: { name, description, price, duration_minutes, is_active, id },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const removeService = createAsyncThunk(
  `${servicesSliceName}/remove`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await deleteService({
        serviceData: { id },
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
