import query from "querystringify";

import { adminClient } from "../utils/axios";
import { getIfExist } from "../utils/getIfExist";

export const getServicesList = queryObj => {
  return adminClient.get(`admin/services/${query.stringify(getIfExist(queryObj), true)}`);
};

export const postService = ({ serviceData }) => {
  return adminClient.post("admin/services/", getIfExist(serviceData));
};

export const putService = ({ serviceData }) => {
  return adminClient.put(`admin/services/${serviceData.id}/`, getIfExist(serviceData));
};

export const deleteService = ({ serviceData }) => {
  return adminClient.delete(`admin/services/${serviceData.id}/`, getIfExist(serviceData));
};
