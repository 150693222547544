export const MOCK_CHAT = [
  {
    id: 202,
    created_at: "2025-01-23T10:26:13.158851+02:00",
    text: "message from Admin",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 201,
    created_at: "2025-01-22T17:30:47.888456+02:00",
    text: "Fghhj",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 200,
    created_at: "2025-01-22T17:30:38.168052+02:00",
    text: "message from Admin",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 199,
    created_at: "2025-01-22T17:23:40.606027+02:00",
    text: "message from Admin",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 198,
    created_at: "2025-01-22T17:19:27.831692+02:00",
    text: "asdad",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 195,
    created_at: "2025-01-22T11:28:31.949628+02:00",
    text: "👍",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 194,
    created_at: "2025-01-22T11:28:16.494622+02:00",
    text: "Gxhxbc",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 193,
    created_at: "2025-01-09T11:36:28.891553+02:00",
    text: "Bbb",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 192,
    created_at: "2025-01-07T16:22:36.646992+02:00",
    text: "Gggggggg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 191,
    created_at: "2025-01-07T16:22:11.244648+02:00",
    text: "Rrrr",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 190,
    created_at: "2025-01-07T16:21:58.704240+02:00",
    text: "Xhkk",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 189,
    created_at: "2025-01-07T15:45:36.554012+02:00",
    text: "👽",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 188,
    created_at: "2025-01-03T13:54:08.837862+02:00",
    text: "Y",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 187,
    created_at: "2025-01-03T13:54:02.291913+02:00",
    text: "Q",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 186,
    created_at: "2024-12-20T17:45:42.931521+02:00",
    text: "Оатат",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 185,
    created_at: "2024-12-20T17:19:06.573687+02:00",
    text: "Hfhhfhd",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 184,
    created_at: "2024-12-20T17:17:43.649543+02:00",
    text: "Test message from admin 54",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 183,
    created_at: "2024-12-20T17:09:36.894573+02:00",
    text: "Xghh",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 182,
    created_at: "2024-12-20T17:08:13.764062+02:00",
    text: "Vhbj",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 181,
    created_at: "2024-12-20T16:29:26.759512+02:00",
    text: "Test message from admin 53",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 180,
    created_at: "2024-12-20T14:45:39.336871+02:00",
    text: "Test message from admin 52",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 179,
    created_at: "2024-12-20T14:43:27.940558+02:00",
    text: "Test message from admin 51",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 178,
    created_at: "2024-12-20T10:58:40.017374+02:00",
    text: "sdfgsdfgsdgf sdfg sdf",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 177,
    created_at: "2024-12-19T15:22:08.967976+02:00",
    text: "Test message from admin 50",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 176,
    created_at: "2024-12-19T15:20:13.143819+02:00",
    text: "Ttt",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 175,
    created_at: "2024-12-19T15:19:45.825763+02:00",
    text: "Test message from admin 49",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 174,
    created_at: "2024-12-19T15:18:41.898509+02:00",
    text: "Test message from admin 48",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 173,
    created_at: "2024-12-19T15:17:23.485438+02:00",
    text: "Tttt",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 172,
    created_at: "2024-12-19T15:16:57.853740+02:00",
    text: "Test message from admin 47",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 171,
    created_at: "2024-12-19T14:52:12.984071+02:00",
    text: "Gggg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 170,
    created_at: "2024-12-19T14:52:05.240107+02:00",
    text: "Eee",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 169,
    created_at: "2024-12-19T14:51:54.586150+02:00",
    text: "Ffff",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 168,
    created_at: "2024-12-19T13:42:17.656150+02:00",
    text: "cgfcg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 167,
    created_at: "2024-12-19T10:42:03.137949+02:00",
    text: "Ggg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 166,
    created_at: "2024-12-19T10:40:10.717992+02:00",
    text: "Test message from admin 46",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 165,
    created_at: "2024-12-19T10:40:01+02:00",
    text: "sf gsdfg sdfg sdfgsdfgsdfg sdflgsd gsdfg sdf gdsfg dsfgsdfglskjdglksdfgj lksfg sd  sdfklg j gsdf gsdflgkjsdf gsdfg kslfdg sdfg sdflgk sdfg sdfg sdlfkgj sdfgsdfg sdf gsdfg dsfgsdfg  sdfg sdf gdsf gsdfg sdfg sdf gsdfg sdfg sdfg sdf gsdf gdf g",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 164,
    created_at: "2024-12-19T08:54:39.616814+02:00",
    text: "sf gsdfg sdfg sdfgsdfgsdfg sdflgsd gsdfg sdf gdsfg dsfgsdfglskjdglksdfgj lksfg sd  sdfklg j gsdf gsdflgkjsdf gsdfg kslfdg sdfg sdflgk sdfg sdfg sdlfkgj sdfgsdfg sdf gsdfg dsfgsdfg  sdfg sdf gdsf gsdfg sdfg sdf gsdfg sdfg sdfg sdf gsdf gdf g",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 163,
    created_at: "2024-12-19T08:45:56.046954+02:00",
    text: "Test message from admin 46",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 162,
    created_at: "2024-12-19T08:37:20.059986+02:00",
    text: "Uuuuuuu",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 161,
    created_at: "2024-12-18T17:53:53.120960+02:00",
    text: "sdfgsdfg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 160,
    created_at: "2024-12-18T16:59:08.511372+02:00",
    text: "Bgggg",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 159,
    created_at: "2024-12-18T16:46:14.168381+02:00",
    text: "Test message from admin 45 Test message from admin 45 Test message from admin 45",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 158,
    created_at: "2024-12-18T16:30:28.123285+02:00",
    text: "Ghhh",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 157,
    created_at: "2024-12-18T14:19:49.505144+02:00",
    text: "Gfcdd",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 156,
    created_at: "2024-12-18T14:04:05.854350+02:00",
    text: "Test message from admin 45",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 123,
    created_at: "2024-12-18T13:01:09.051500+02:00",
    text: "Fghj",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 122,
    created_at: "2024-12-18T13:00:27+02:00",
    text: "Test message from admin 34",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 121,
    created_at: "2024-12-18T12:58:37.695338+02:00",
    text: "Test message from admin 33",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
  {
    id: 120,
    created_at: "2024-12-18T12:19:19.909758+02:00",
    text: "Eeeee",
    sender_display: "uNick Bard",
    is_sender_patient: true,
    is_sender_current_user: true,
  },
  {
    id: 119,
    created_at: "2024-12-18T12:14:13.165292+02:00",
    text: "Test message from admin 32",
    sender_display: "root",
    is_sender_patient: false,
    is_sender_current_user: false,
  },
];
