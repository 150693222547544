import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { FiEye, FiTrash } from "react-icons/fi";
import { BsPencil } from "react-icons/bs";

import { getPatientImage } from "../../utils/mockImages";

const PatientListItem = ({ patient, showShortInfoId, deletePatientProfile }) => {
  const handleDeletePatientProfile = () => {
    deletePatientProfile(patient);
  };

  return (
    <tr>
      <th className="p-3">{patient.id}</th>
      <td className="py-3">
        <Link to={`/patients/${patient.id}`} className="text-dark">
          <div className="d-flex align-items-center">
            {/*<img*/}
            {/*  src={patient.image || getPatientImage(patient.id)}*/}
            {/*  className="avatar avatar-md-sm rounded-circle shadow"*/}
            {/*  alt=""*/}
            {/*/>*/}
            <span className="ms-2">{`${patient?.first_name} ${patient?.last_name}`}</span>
          </div>
        </Link>
      </td>
      <td className="p-3">
        {patient.birth_date ? moment().diff(moment(patient.birth_date, "YYYY-MM-DD"), "years") : ""}
      </td>
      <td className="p-3">{patient.birth_date}</td>
      <td className="p-3">{patient.gender}</td>
      <td className="p-3">{patient.address}</td>
      <td className="p-3">
        <div>{patient.primary_phone_number}</div>
        <div>{patient.secondary_phone_number}</div>
      </td>
      {/*<td className="p-3">{patient.Department}</td>*/}
      <td className="p-3">{patient.medicate_number}</td>
      {/*<td className="p-3">{patient.time}</td>*/}
      {/*<td className="p-3">*/}
      {/*  {patient.status === "Approved" ? (*/}
      {/*    <span className="badge bg-soft-success">Approved</span>*/}
      {/*  ) : (*/}
      {/*    <span className="badge bg-soft-warning">Pending</span>*/}
      {/*  )}*/}
      {/*</td>*/}
      <td className="text-end p-3">
        <div className="d-flex patientsActions">
          <Link
            to="#"
            className="btn btn-icon btn-pills btn-soft-primary"
            onClick={() => showShortInfoId(patient.id)}
          >
            <FiEye />
          </Link>
          <Link
            className="btn btn-icon btn-pills btn-soft-success mx-1"
            to={`/patients/${patient.id}`}
          >
            <BsPencil />
          </Link>
          <Link
            to="#"
            className="btn btn-icon btn-pills btn-soft-danger"
            onClick={handleDeletePatientProfile}
          >
            <FiTrash />
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default PatientListItem;
