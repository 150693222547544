import { createAsyncThunk } from "@reduxjs/toolkit";
import { authLogin, getUserPermissions } from "../../api/auth";

export const login = createAsyncThunk(`auth/login`, async ({ email, password }, thunkAPI) => {
  try {
    const response = await authLogin({ email, password });
    const { data, meta } = response.data;
    // const permissionsResponse = await getUserPermissions(meta.access_token);
    const permissionsResponse = {};

    return {
      data,
      meta,
      permissions: permissionsResponse.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      message: "Username or password is incorrect",
    });
  }
});
