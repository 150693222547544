import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import query from "querystringify";

import { addService, getServices, updateService } from "../../store/actions/services";
import { history } from "../../utils/history";

export default function CreateUpdateServiceModal({
  isModalOpen,
  setIsModalOpen,
  serviceOnUpdate = {},
  setServiceOnUpdate,
}) {
  const dispatch = useDispatch();
  const [tip, setTip] = useState({
    text: "",
    isError: false,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
    price: Yup.string().required("Price is required"),
    duration_minutes: Yup.string().required("Duration minutes is required"),
    is_active: Yup.boolean(),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { is_active: true },
  };

  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (serviceOnUpdate && Object.keys(serviceOnUpdate).length > 0) {
      reset({
        name: serviceOnUpdate.name,
        description: serviceOnUpdate.description || "",
        price: serviceOnUpdate.price,
        duration_minutes: serviceOnUpdate.duration_minutes,
        is_active: serviceOnUpdate.is_active || false,
      });
    } else {
      reset();
    }
  }, [serviceOnUpdate, reset]);

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);

  const onSubmit = async ({ name, description, price, duration_minutes, is_active }) => {
    if (serviceOnUpdate.id) {
      const res = await dispatch(
        updateService({
          ...serviceOnUpdate,
          name,
          description,
          price,
          duration_minutes,
          is_active,
        }),
      );

      dataProcessing(res, "updated");
    } else {
      const res = await dispatch(
        addService({ name, description, price, duration_minutes, is_active }),
      );

      dataProcessing(res, "added");
    }

    dispatch(getServices(queries));
  };

  const dataProcessing = (res, action) => {
    if (res.payload?.data.name) {
      setTip({ text: `You ${action} ${res.payload.data.name} service`, isError: false });
      const timerId = setTimeout(() => {
        setTip({ text: "", isError: false });
        handleCloseModal();
        clearTimeout(timerId);
      }, 3000);
    }

    if (res.error?.message) {
      setTip({ text: `Something went wrong...`, isError: true });
    }
  };

  const handleCloseModal = () => {
    setServiceOnUpdate({});
    reset(formOptions.defaultValues);
    setIsModalOpen(false);
  };

  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <h5 className="modal-title" id="exampleModalLabel1">
          New service
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{`Service Name${checkIfRequired("name") ? " *" : ""}`}</label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  className={classNames("form-control", {
                    "is-invalid": errors.name,
                  })}
                  placeholder="Service name"
                  {...register("name")}
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{`Price${checkIfRequired("price") ? " *" : ""}`}</label>
                <input
                  name="price"
                  id="price"
                  type="number"
                  className={classNames("form-control", {
                    "is-invalid": errors.price,
                  })}
                  placeholder="Price"
                  {...register("price")}
                />
                <div className="invalid-feedback">{errors.price?.message}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{`Duration Minutes${checkIfRequired("duration_minutes") ? " *" : ""}`}</label>
                <input
                  name="duration_minutes"
                  id="duration_minutes"
                  type="number"
                  className={classNames("form-control", {
                    "is-invalid": errors.duration_minutes,
                  })}
                  placeholder="Duration Minutes"
                  {...register("duration_minutes")}
                />
                <div className="invalid-feedback">{errors.duration_minutes?.message}</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Is Service Active</label>
                <div className="form-check">
                  <input
                    name="is_active"
                    id="is_active"
                    type="checkbox"
                    className={classNames("form-check-input", {
                      "is-invalid": errors.is_active,
                    })}
                    {...register("is_active")}
                  />
                  <label className="form-check-label" htmlFor="is_active">
                    Active
                  </label>
                  <div className="invalid-feedback">{errors.is_active?.message}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  id="description"
                  className={classNames("form-control", {
                    "is-invalid": errors.description,
                  })}
                  placeholder="Description"
                  {...register("description")}
                  rows={4}
                />
                <div className="invalid-feedback">{errors.description?.message}</div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4">
              <button disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {serviceOnUpdate.id ? "Update" : "Create"}
              </button>
            </div>
            <div className="col-md-8">
              {tip.text && (
                <div
                  className={classNames("fw-bold", {
                    "text-danger": tip.isError,
                    "text-success": !tip.isError,
                  })}
                >
                  {tip.text}
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
