import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineSend } from "react-icons/ai";
import { FiPaperclip, FiSmile } from "react-icons/fi";
import SimpleBar from "simplebar-react";
import { RiTimeLine } from "react-icons/ri";
import classNames from "classnames";
import moment from "moment";

import chatBg from "../../assets/images/bg/bg-chat.png";
import doctor1 from "../../assets/images/doctors/01.jpg";
import doctor2 from "../../assets/images/doctors/02.jpg";

import { MOCK_CHAT } from "../../_mockData/chat";

export default function Chat() {
  const simpleBarRef = useRef(null);
  const [chat, setChat] = useState([...MOCK_CHAT].reverse());
  const [newMessageText, setNewMessageText] = useState("");

  useEffect(() => {
    if (simpleBarRef.current) {
      const scrollElement = simpleBarRef.current.getScrollElement();
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [chat]);

  const sendMessage = () => {
    setChat(prev => [
      ...prev,
      {
        id: Date.now(),
        created_at: new Date(),
        text: newMessageText,
        sender_display: "root",
        is_sender_patient: false,
        is_sender_current_user: false,
      },
    ]);
    setNewMessageText("");
  };

  return (
    <>
      <SimpleBar
        style={{
          maxHeight: "650px",
          backgroundImage: `url(${chatBg})`,
          backgroundPosition: "center",
        }}
        ref={simpleBarRef}
      >
        <ul className="p-4 list-unstyled mb-0 chat">
          {chat.map(({ id, sender_display, text, created_at }) => {
            const isAdminSender = sender_display === "root";

            const messageDate = moment(created_at);
            const isToday = messageDate.isSame(moment(), "day");
            const formattedDate = isToday
              ? messageDate.format("HH:mm") // Формат для сегодняшних сообщений
              : messageDate.format("DD.MM.YYYY HH:mm");

            return (
              <li
                className={classNames({ "chat-right": isAdminSender })}
                key={id}
                onDoubleClick={e => {
                  if (isAdminSender) return;

                  console.log(123123);
                }}
              >
                <div className="d-inline-block">
                  <div className="d-flex chat-type mb-3">
                    <div className="position-relative chat-user-image">
                      <img
                        src={isAdminSender ? doctor1 : doctor2}
                        className="avatar avatar-md-sm rounded-circle border shadow"
                        alt=""
                      />
                      {/*<i className="mdi mdi-checkbox-blank-circle text-success on-off align-text-bottom"></i>*/}
                    </div>

                    <div className="chat-msg" style={{ maxWidth: "500px" }}>
                      <p className="text-muted small shadow px-3 py-2 bg-light rounded mb-1">
                        {text}
                      </p>
                      <small className="text-muted msg-time">
                        <RiTimeLine className="me-1 mb-0" />
                        {formattedDate}
                      </small>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </SimpleBar>

      <div className="p-2 rounded-bottom shadow">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control border"
              placeholder="Enter Message..."
              value={newMessageText}
              onChange={e => setNewMessageText(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter") sendMessage();
              }}
            />
          </div>
          <div className="col-auto">
            <Link to="#" className="btn btn-icon btn-primary" onClick={sendMessage}>
              <AiOutlineSend />
            </Link>
            <Link to="#" className="btn btn-icon btn-primary mx-1">
              <FiSmile />
            </Link>
            <Link to="#" className="btn btn-icon btn-primary">
              <FiPaperclip />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
